body {
  margin: 0;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Th';
  src: url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Th.eot');
  src: url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Th.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Th.woff') format('woff'),
  url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Th.ttf') format('truetype'),
  url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Th.svg#vegurlight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Md';
  src: url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Md.eot');
  src: url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Md.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Md.woff') format('woff'),
  url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Md.ttf') format('truetype'),
  url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Md.svg#vegurlight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Lt';
  src: url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Lt.eot');
  src: url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Lt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Lt.woff') format('woff'),
  url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Lt.ttf') format('truetype'),
  url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Lt.svg#vegurlight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Bd';
  src: url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Bd.eot');
  src: url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Bd.eot?#iefix') format('embedded-opentype'),
  url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Bd.woff') format('woff'),
  url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Bd.ttf') format('truetype'),
  url('../fonts/HelveticaNeue/HelveticaNeueLTStd-Bd.svg#vegurlight') format('svg');
  font-weight: normal;
  font-style: normal;
}

* { font-family: "HelveticaNeueLTStd-Lt",sans-serif; }


.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: block;
  position: relative;
}

header {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 50px;
  z-index: 2;
  background-color: transparent;

  .logo {
    background-image: url('../images/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top;
    width: 108px;
    height: 27px;
    position: absolute;
    left: 0;
    top: 20px;
    float: left;
  }
}

main {
  @media only screen and (max-width: 768px) {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/bg-china.jpg');
    background-size: cover;
    background-position: center top;
  }
  background-image: url('../images/bg-china.jpg');
  background-position: center top;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1;

  .centeriko {
    @media only screen and (max-width: 768px) {
      top: 43vh;
    }
    position: absolute;
    top: 50vh;
    left: 0;
    transform: translateY(-50%);
    max-width: 450px;
  }

  h1, p {
    color: #fff;
    margin-top: 0;
    font-family: "HelveticaNeueLTStd-Lt",sans-serif;
  }

  h1 {
    font-size: 32px;
    @media only screen and (min-width: 768px) {
      font-size: 48px;
    }
  }

  text {
    p {
      font-size: 14px;
    }
  }
}

footer {
  @media only screen and (max-width: 768px) {
    padding: 10px 0;
    height: 107px;
  }
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 98px;
  left: 0;
  background-color: #1a1a1a;
  color: #ffffff;
  z-index: 2;
  padding: 20px 0;
  box-sizing: border-box;
  vertical-align: middle;

  .col-left,
  .col-center,
  .col-right {
    float: left;
    display: inline-block;
    height: 45px;
  }

  .col-left {
    width: 0%;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .col-center {
    width: 65%;
    @media only screen and (max-width: 768px) {
      display: block !important;
      clear: both !important;
      float: none !important;
      text-align: center !important;
      margin: 0 auto !important;
      width: 100% !important
    }
  }

  .col-right {
    width: 35%;
    @media only screen and (max-width: 768px) {
      display: block !important;
      clear: both !important;
      float: none !important;
      text-align: center !important;
      margin: 0 auto !important;
      width: 100% !important
    }
  }

  a {
    @media only screen and (max-width: 768px) {
      line-height: 21px;
      display: block;
    }

    color: #ffffff;
    font-size: 14px;
    line-height: 50px;
    text-decoration: blink;
    font-family: "HelveticaNeueLTStd-Lt",sans-serif;
    transition: all 0.3s;

    &:hover {
      text-decoration: underline;
    }

    & + a {
      margin-left: 30px;
      @media only screen and (max-width: 768px) {
        margin-left: 0px;
      }
    }

    &.btn.contact {
      background-color: #000;
      border-radius: 25px;
      line-height: 45px;
      width: 165px;
      text-align: center;
      float: right;
      border: 1px solid #fff;

      @media only screen and (max-width: 768px) {
        display: block !important;
        float: none;
        margin: 0 auto;
        text-align: center;
      }
      &:hover {
        background-color: #fff;
        color: #000;
        text-decoration: blink !important;
      }
    }
  }

}
